@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;500;700;800&display=swap");

body {
    margin: 0;
    font-family: "apercu-mono-regular-pro","Open Sans", sans-serif;
    background-color: #000;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #fff;
    font-size: 16px;
    cursor: url("cursor.png") 0 0, pointer;
    
    
}

.container{
    max-width: 1080px;
    margin:auto;
    padding:15px;
}

.bigcontainer{
    max-width: 1440px;
    margin:auto;
    padding:30px;
    margin-bottom: 100px;
}

.greencontainer{
    background: #2eff2e;
    color: #000 !important;
    box-shadow: 0px 0px 15px 2px #00e600;
    border-radius: 2px;
}

.greencontainer h2, .greencontainer a{
    color: #000 !important;
}

.copyright{
    z-index:10000;
    padding:15px;
    color:#2eff2e;
    font-size:10px;
    
}

.copyright a, .copyright a:link, .copyright a:visited{
    color:#2eff2e;
}


.logotitle{
    max-width:60%;
    margin:auto;
}

h1{
    font-family: "ghastlypixemedium";
    font-size:120px;
    color: #2eff2e;
    font-weight:100;
}

h2 {
    font-family: "ghastlypixemedium";
    margin-top: 0px;
    margin-bottom: 10px;
    font-size: 52px;
    color: #2eff2e;
}

h3 {
    margin-top: 0px;
    margin-bottom: 10px;
    font-weight: 900;
    font-size:24px;
    font-weight:bold;
}

h4 {
    margin-top: 0px;
    margin-bottom: 10px;
    font-weight: bold;
}

@media all and (max-width: 1080px) {
    .logotitle{
        max-width:88%;
    }
}

@media all and (max-width: 800px) {
    h1{
        font-size:80px;
    }
    h2 {
        font-size: 32px;
    }

    h3{
        font-size: 20px;
    }

    .logotitle{
        max-width:96%;
    }
}

@media all and (max-width: 500px) {
    h1{
        font-size:60px;
    }
    h2 {
        font-size: 26px;
    }
}

hr {
    border-bottom: 1px solid #222;
    margin: 100px auto;
}

.smallhr {
    border-bottom: 1px solid #333;
    border-top: #040404;
    border-left: none;
    border-right: none;
    margin: 50px auto;
}

.left{
    float: left;
}

.right{
    float: right;
}

.clearfix{
    clear:both;
}

.p40{
    width: 40%;
}

.p45{
    width: 45%;
}

.p50{
    width: 50%;
}

.p60{
    width: 60%;
}

.p33pad{
    width: calc(33% - 60px);
    margin: 30px;
}

.p25pad{
    width: calc(25% - 60px);
    margin: 30px;
}

@media all and (max-width: 720px)
{
    .p40, .p45{
        width:100%;
    }
    .p50{
        width:100%;
    }
    .p60{
        width:100%;
    }

    .p33pad, .p25pad{
        width: calc(50% - 40px);
        margin: 20px;
    }
}

a:link,
a:visited,
a,
a span {
    color: #111;
    text-decoration: none;
    font-weight: bold;
    cursor: url("cursor_pointer.png") 0 0, pointer;
}

a:hover {
    opacity: 0.5;
}

::-moz-selection {
    /* Code for Firefox */
    color: #030303;
    background: white;
}

::selection {
    color: #030303;
    background: white;
}

.absoluteheader{
    position:absolute;
    width:100%;
}

.absoluteheader .logotitle{
    max-height:120px;
}

@media all and (max-width:800px)
{
    .absoluteheader .logotitle{
        max-height:70px;
    }
}


/* width */
::-webkit-scrollbar {
width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
background: #343f29;
}

/* Handle */
::-webkit-scrollbar-thumb {
background: #2eff2e;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
background: #ff0000;
}


.fadeIn{
    opacity:100 !important;
    visibility: visible !important;
    transition: opacity 0.3s linear;
    display:block !important;
    pointer-events: all !important;
}

.loading{
    margin:auto;
    display:block;
}



.wrapper {

}

.title{
    z-index:1001;
    top:25px;
    position: sticky;
    width:60%;
    left:20%;

}

.title img{
    max-width:100%;
}

#title{
    text-align:center;
    position:relative;
}



.sound{
    max-height:40px;
    position:fixed;
    left:35px;
    margin-top:25px;
    z-index:30;
    display:block;
    cursor: url("cursor_pointer.png") 0 0, pointer;
    animation-name: tweety;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-direction: reverse;
    animation-timing-function: linear;
}


@keyframes tweety {
    0% {top:0px;}
    30%{top:15px;}
    70%{top:0px;}
    90%{top:5px;}
    100% {top:0px;}
}
.shine{
    box-shadow: 0px 0px 15px 2px #00e600;
}

.shine:hover{
    animation: shine 5s ease-in-out infinite;
}

.shine::after{
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.shine:hover::after {    
    opacity:1;
}

@keyframes shine{
    0% {
        box-shadow: 0px 0px 100px 20px #00e600;
    }
    50% {
        box-shadow: 0px 0px 80px 15px #00e600;
    }
    100% {
        box-shadow: 0px 0px 100px 20px #00e600;
    }
}

.navi{
    padding:20px;
    text-align:center;
    position:relative;
    z-index:1000;
}

.navi .button{
    display:inline-block;
    padding:10px 20px;
    margin: 0 20px;
    text-transform: uppercase;
    border-radius:5px;
    width:200px;
}

.navi a:active img{
    transform: scale(0.98);
}

.navi .button img{
    width:100%;
}

.navi .button.locked{
    opacity:0.3 !important;
}
.navi .button.locked a:hover{
    opacity:1;
}

.navi .button.locked:active{
    opacity:1;
    animation: rumble 0.2s linear infinite;
}

@keyframes rumble{
    0% {
        transform: rotate(0);
    }
    25% {
        transform: rotate(-5deg);
    }
    50% {
        transform: rotate(5deg);
    }
    75% {
        transform: rotate(-5deg);
    }
    100% {
        transform: rotate(0);
    }
}

@media (orientation: portrait){

    .navi{
        max-width: 400px;
        margin: 10px auto;
    }
    .navi .button{
        width: calc(50% - 00px);
        max-width: 200px;
        margin:00px 00px 0 0px;
        padding: 0;
        float:left;
    }
}

/* LANDING PAGE */

.portal{
    max-width:60%;
    margin:30px auto 50px;
    display:block;
    position:relative;
    z-index:10;
    
}

.portal:hover{
    cursor: url("cursor_pointer.png") 0 0, pointer;
}


.footer{
    padding-bottom:50px;
    text-align:center;
}

.footer a{
    padding:0 10px;
}

.footer img{
    max-width:50px;
    max-height:50px;
}

.twitter, .discord, .mirror{
    cursor: url("cursor_pointer.png") 0 0, pointer;
}

.twitter:active, .discord:active, .mirror:active, .sound:active{
    transform: scale(0.95);
}

.discord_locked{

}

.discord_locked:active{
    animation: rumble 0.2s linear infinite;
}




.wrapframe{
    position:sticky;
    top:0;
}

@media all and (orientation: portrait)
{

    .wrapframe{
        position:relative;
        top:0;
        overflow:hidden;
        margin-bottom:30px;
    }

}

.framebehind{
    left:0;
    right:0;
    bottom:0;
    z-index:1;
    top:8%;
    width: 100%;
}

.map{
    max-width:100%;
    cursor: url("cursor_pointer.png") 0 0, pointer;
}

.teammember{

}

.teampic{
    width:100%;
    margin-bottom:15px;
}

.shoppic{
    margin-bottom:15px;
    max-height:100%;
}


.shopitem{
    position:relative;
    height:200px;
    text-align:center;
}

.shopitem .lock{
    position:absolute;
    width:30%;
    bottom:15%;
    right:15%;
    z-index: 1;
}


.mintbuttonlock{
    position:relative;
    top: 15px;
    right: 50px;
    max-width:70px !important;
}

.movelock{
    animation: rumble 0.2s linear infinite;
}

.mintbuttonwrap a{
    display:block;
    position:relative;
}

.bigmap{
    position:absolute;
    top:5px;
    left:15px;
    right: 15px;
    width: calc(100% - 30px);
    z-index:10000;
    display:none;
}

.bigmap img{
    width:100%;
}

.bigmap.visible{
    display:block;
}

.subtitleteam{
    height:54px;
}

@media all and (orientation: portrait)
{
    .bigmap.visible{
        display: none;
    }

    
    .mintbuttonlock{
        max-width:70px !important;
        right:-10%;
        top:0;
        position:absolute;
    }


}


