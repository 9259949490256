/*
 * Web Fonts from colophon-foundry.org
 *
 * The fonts included in this stylesheet are subject to the End User License you purchased
 * from Colophon Foundry. The fonts are protected under domestic and international trademark and
 * copyright law. You are prohibited from modifying, reverse engineering, duplicating, or
 * distributing this font software.
 *
 * (c) 2022 Colophon Foundry
 *
 * Licenced to Anne Stock 
 */

@font-face {
    font-family: 'apercu-mono-regular-pro';
    src: url('apercu-mono-regular-pro.eot');
    src: url('apercu-mono-regular-pro.eot?#iefix') format('embedded-opentype'),
         url('apercu-mono-regular-pro.woff2') format('woff2'),
         url('apercu-mono-regular-pro.woff') format('woff'),
         url('apercu-mono-regular-pro.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'ghastlypixemedium';
    src: url('ghastlypixe-webfont.woff2') format('woff2'),
         url('ghastlypixe-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
