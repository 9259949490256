@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;500;700;800&display=swap");

body {
    margin: 0;
    font-family: "apercu-mono-regular-pro","Open Sans", sans-serif;
    background-color: #111;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #111;
    font-size: 16px;
    cursor: url("cursor.png") 0 0, pointer;
    
}

.copyright{
    z-index:10000;
    position:fixed;
    bottom:5px;
    right:5px;
    color:#2eff2e;
    font-size:10px;
    text-align:right !important;
    
}

.copyright a{
    text-align:right !important;
    margin: 0;
    padding: 0;
}

h1{
    font-family: "ghastlypixemedium";
    font-size:120px;
    color: #2eff2e;
    font-weight:100;
}

h2 {
    font-family: "ghastlypixemedium";
    margin-top: 0px;
    margin-bottom: 10px;
    font-size: 44px;
}

h3 {
    margin-top: 0px;
    margin-bottom: 10px;
    font-weight: bold;
    font-size:22px;
}

@media all and (max-width: 500) {
    h2 {
        font-size: 26px;
    }
}

hr {
    border-bottom: 1px solid #222;
    margin: 100px auto;
}

.smallhr {
    border-bottom: 1px solid #333;
    border-top: #040404;
    border-left: none;
    border-right: none;
    margin: 50px auto;
}

a:link,
a:visited,
a,
a span {
    color: #111;
    text-decoration: none;
    font-weight: bold;
    cursor: url("cursor_pointer.png") 0 0, pointer;
}

a:hover {
    opacity: 0.5;
}

::-moz-selection {
    /* Code for Firefox */
    color: #030303;
    background: white;
}

::selection {
    color: #030303;
    background: white;
}


  .fadeIn{
      opacity:100 !important;
      visibility: visible !important;
      transition: opacity 0.3s linear;
      display:block !important;
      pointer-events: all !important;
  }





.wrapper {
    height:100vh;
    overflow-y:auto;
    overflow-x:hidden;

    max-height:2000px;
    position: relative;
    overflow:hidden;
    transition: all 0.6s linear;

}


.twitter{
    max-height:50px;
    position:fixed;
    left:90px;
    top:20px;
    animation-name: tweety;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-direction: reverse;
    animation-timing-function: linear;
    z-index:15;
    display:block;
    margin-top:20px;
}

.discord{
    max-height:50px;
    position:fixed;
    left:160px;
    top:20px;
    animation-name: tweety;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-direction: reverse;
    animation-timing-function: linear;
    z-index:15;
    display:block;
    margin-top:20px;
}

.sound{
    max-height:30px;
    position:fixed;
    left:25px;
    top:25px;
    z-index:30;
    display:block;
    cursor: url("cursor_pointer.png") 0 0, pointer;
}


.twitter img, .discord img{
    max-width:50px;
    max-height:50px;
}

.logotitle{
    max-height:120px;
}

.logotitle:active{
    transform: scale(0.98);
}

header{
    position:absolute;
    top:0;
    display:flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width:100%;
    transform-style: preserve-3d;
    z-index:11;
    overflow:visible;
}

.loginbutton{
    position:fixed;
    top: 10px;
    right:10px;
    z-index: 10000;
    width:200px;
}

.loginbutton:hover{
    cursor: url("cursor_pointer.png") 0 0, pointer;
}

.loginbutton img{
    width:100%;
}

@media all and (max-width:960px)
{
    .loginbutton{
        width:150px;
        top:22px;
    }
}

.loginbutton img:hover{
    opacity: 0.5;
}

.loginbutton img:active{
    transform: scale(0.98);
}

.header{
    height:100%;
}

.wrapsubbox{
    position:fixed;
    top:50%;
    left:calc(50% - 120px);
    transform: translateY(-50%);
    opacity:0;
    visibility:none;
    z-index:15;
    transition: opacity 1s;
}

.wrapsubbox.visible{
    opacity: 1;
    visibility: visible;
}

.wrapsubbox img{
    max-width:240px;
}

.close{
    max-width:200px;
    float:right;
    top:-40px;
    position:relative;
}

.moreinfo, .subscribe{
    position:absolute;
    left:0;
    right:0;
    top:0;
    bottom:0;
    z-index:16;
    padding:250px;
    padding-top:150px;
    background:rgba(0,0,0,0.8);
    transform: translateZ(-0px) scale(1.1);
    color:#2eff2e;
    overflow:scroll;
}

.subscribe .content{
    margin:auto;
    width:600px;
    max-width:90%;
    text-align:center;
    padding-top:50px;
}

.subscribe input{
    font-family: "apercu-mono-regular-pro","Open Sans", sans-serif;
    background:rgba(0,0,0,0);
    color:#2eff2e;
    font-size: 30px;
    width:100%;
    border: rgba(0,0,0,0);
    border-bottom: 3px solid #2eff2e;
    margin-bottom:30px;
    text-align:center;
    z-index:1000;
}

.subscribe input:focus{
    border: 3px solid #2eff2e;
}

.subscribe button{
    color:#000;
    font-size: 30px;
    background:#2eff2e;
    font-family: "apercu-mono-regular-pro","Open Sans", sans-serif;
    text-transform: uppercase;
    border: none;
    padding: 10px;
    z-index:1000;
    cursor: url("cursor_pointer.png") 0 0, pointer;
}

.subscribe button:hover{
    opacity:0.7;
}

.nopointer{
    pointer-events: none;
}

.l{
    position:absolute;
    height:100%;
    width:100%;
    object-fit: cover;
    z-index:-1;

}

.l0{
    transform: translateZ(-20px) scale(3);
    position:fixed;
}

/* CLOUDS */
.l1{
    z-index:1;
    transition: all 0.6s linear;
}

/* DIGITAL  */
.l2{
    position:absolute;
    top:0;
    width:100%;
    z-index:2;
    object-fit:cover;
    z-index:2;
    transition: all 0.6s linear;

}

/* BLUE MOUNTAINS  */
.l3{
    z-index:3;
    transition: all 0.6s linear;
}

/* FOREST */
.l4{
    z-index:4;
    transition: all 0.6s linear;
}

/* FOG */
.l42{
    z-index:5;
    transition: all 0.6s linear;
}

/* Fog */
.lfog{
    object-fit: cover;
    animation-name: fog;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-direction: reverse;
    animation-timing-function: linear;
    z-index:6;
}



/* Mountains */
.l5{
    z-index:7;
    position:fixed;
    transition: all 0.6s linear;
}

/* Shadow */
.l6{
    z-index:20;
    transition: all 0.6s linear;
}

.lf1{

}


.f{
    position:fixed;
    height:100%;
    width:100%;
    z-index:20;
    transition: all 2s ease 0s;
    pointer-events: none;
    bottom:-600px;
}



.show-frog{
    transform: translateY(3600px) scale(1.5);
}


@keyframes fog {
    0% {top:0px;}
    50%{top:5px;}
    100% {top:0px;}
}

.f1{
    animation-name: frog1;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-direction: reverse;
    animation-timing-function: linear;
}
.f2{
    animation-name: frog2;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-direction: reverse;
    animation-timing-function: linear;
}

.f3{
    animation-name: frog3;
    animation-duration: 6s;
    animation-iteration-count: infinite;
    animation-direction: reverse;
    animation-timing-function: linear;
}

.f4{
    animation-name: frog4;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-direction: reverse;
    animation-timing-function: linear;
}


@keyframes frog1 {
    0% {top:0px;}
    50%{top:0px;}
    90%{top:5px;}
    100% {top:0px;}
}

@keyframes frog2 {
    0% {top:0px;}
    50%{top:5px;}
    70%{top:0px;}
    100% {top:0px;}
}

@keyframes frog3 {
    0% {top:3px;}
    50%{top:0px;}
    100% {top:0px;}
}

@keyframes frog4 {
    0% {top:0px;}
    50%{top:0px;}
    90%{top:5px;}
    100% {top:0px;}
}

.blinkfrog8 {
    animation: blink8 5s steps(1, end) infinite;
}
  
  @keyframes blink8 {
    0% {
      opacity: 0;
    }
    45% {
        opacity: 0;
      }
    50% {
      opacity: 1;
    }
    55% {
        opacity: 0;
      }
    100% {
      opacity: 0;
    }
}


.blinkfrog13 {
    animation: blink13 5s steps(1, end) infinite;
}
  
  @keyframes blink13 {
    0% {
      opacity: 0;
    }
    45% {
        opacity: 0;
      }
    60% {
      opacity: 0;
    }
    65% {
        opacity: 1;
      }
    72%{
        opacity:0;
    }
    100% {
      opacity: 0;
    }
}
  

.container{
}

.container2{
    transition: opacity 3s;  
    opacity:0;
}

.loading{
    position:absolute;
    top:calc(50% - 50px);
    height:100px;
    left:calc(50%);
    transform: translateX(-50%);
    animation-name: loady;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-direction: reverse;
    animation-timing-function: linear;

}

@keyframes loady {
    0% {top:calc(50% - 50px);}
    30%{top:calc(50% - 40px);}
    70%{top:calc(50% - 50px);}
    90%{top:calc(50% - 55px);}
    100% {top:calc(50% - 50px);}
}



code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}


.left{
    float:left;
}

.right{
    float:right;
}

.centertext, .textcenter{
    text-align:center;
}


@media all and (max-width:1600px)
{

    body{
        font-size:12px;
    }
        
}

@media all and (max-width:1400px)
{

    .moreinfo, .subscribe{
        padding:200px;
        padding-bottom:400px;
    }
}


@media all and (max-width:1080px)
{

    .moreinfo{
        padding:150px;
        padding-bottom:300px;
    }
    
    .subscribe{
        padding:200px 55px 55px 55px;
    }

    .title h1{
        font-size: 80px !important;
    }

    .discord, .twitter{
        margin-top:20px;
    }

}

@media all and (max-height:960px)
{


    .discord, .twitter{
        margin-top:20px;
    }
    
}



@media all and (max-width:720px) and (min-height:720px)
{
    .discord, .twitter{
        margin-top:20px;
    }
}

@media all and (orientation: portrait)
{

    .l5{
        object-fit:contain;
        object-position: bottom center;
        width:120%;
    }

    .lf1{
        object-fit: contain !important;
        object-position: bottom center;
    }

    .f{
        width:120%;
        left:-10%;
    }


}

@media all and (max-width:800px)
{
    
    .discord, .twitter{
        margin-top:20px;
        
    }

    .discord{
        right:15px;
    }

    .twitter{
        right:25px;
    }

    .twitter img, .discord img{
        max-width:40px;
        max-height:40px;
    }

    .close{
        top:50px;
        right:0px;
        position:absolute;
        max-width:150px;
    }

    .moreinfo {
        padding:120px 55px 205px 55px;
    }

    .subscribe{
        padding:200px 55px 55px 55px;
    }

    .navi{
        font-size:20px;
    }

    .title{
        padding-top:60px;
        width:calc(100% - 30px);
        left:15px;
    }
    
}


/* VIDEOS */

.wrapvideochapter{
    max-width:100%;

}

.videochapter{
    max-width:100%;

}

.videochapter video{
    max-width:100%;

}

.wrapvideochapter .container{
    margin-top: 10%;
}


.wrapvideochapter .containertextwrap{
    max-width: 60%;
    height: auto;
    margin:auto;
    padding:30px;
    line-height:40px;

}

.wrapvideochapter .containertextwrap:before{
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: black;
    opacity: 1;
    z-index: -1;

    /* Applying blur */
    filter: blur(70px);
}

.wrapvideochapter .containertext{
filter: blur(0);
}

#v0 {
    position: fixed;
    bottom: -5%;
    right: 0;
    width: 105%;
    opacity:0.9;
}

#set-height {
    display: block;
}

section {
    z-index: 1;
    position: relative;
}

.content {
    position: sticky;
    position: -webkit-sticky;
    width: 100%;
    top: 33.3%;
    color:#2eff2e;
    text-align:center;
    padding: 15px;
}


.wrapvideochapter h1 {
    font-size: 5em;
    display: inline-block;
    padding: 10px 15px;
    border-radius: 5px;
    margin: 0;
}

.wrapvideochapter p { 
    font-size: 2em; 
}

section:nth-of-type(1) {
    height: 500px;
}
section:nth-of-type(2) {
    height: 500px;
}
section:nth-of-type(3) {
    height: 500px;
}
section:nth-of-type(4) {
    height: 500px;
}
section:nth-of-type(5) {
    height: 500px;
}

.entered {
	-webkit-animation: text-focus-in 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: text-focus-in 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2018-7-4 16:24:12
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation text-focus-in
 * ----------------------------------------
 */
 @-webkit-keyframes text-focus-in {
    0% {
      -webkit-filter: blur(12px);
              filter: blur(12px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0px);
              filter: blur(0px);
      opacity: 1;
    }
  }
  @keyframes text-focus-in {
    0% {
      -webkit-filter: blur(12px);
              filter: blur(12px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0px);
              filter: blur(0px);
      opacity: 1;
    }
  }



  .fairyfrenpopup{
    position: absolute;
    z-index: 10001;
    left:-30px;
    top: 50;
    width:250px;
    transition: transform 0.2s ease-in-out;    
    overflow: hidden;
  }

  .fairyfrenpopup img{
    max-width:100%;
    transition: all 0.2s ease-in-out;
    width: 100%;
    height: 100%;
    transform: rotate(80deg);
}

  .fairyfrenpopup.hide img {
    transform:rotate(75deg) translateY(30%);
  }

  @media all and (max-width:1080px)
  {
    .fairyfrenpopup{
        width:140px;
      }
  }

  /* CONNECT WALLET */

  .connectwallet{
    position: fixed;
    top: 20px;
    right:20px;
    z-index:3;
    max-width:200px;

  }

  .connectwallet a{
    color: #2eff2e !important;
  }

  .connectwallet img{
    max-width: 100%;
  }

  #walletconnect-wrapper{
    display:none !important;
  }

  .walletaddress{
    color: #2eff2e !important;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;

  }

  @media all and (max-width:720px)
{
    .walletaddress{
        display:none;
    }
}

.mintbuttonwrap{
    transition: all 0.2s ease-in-out;
    cursor: url("cursor_pointer.png") 0 0, pointer;
}

.mintbuttonwrap:hover{
    opacity:0.5;
}

.mintbuttonwrap img:active{
    transform: scale(0.98);
}

.mintbuttonwrap.hide{
    opacity:0;
    z-index:0;
}

.mintbuttonwrap.hidden{
    display:none;
}

.mintgoback{
    position: fixed;
    bottom: 30px;
    left: calc(50% - 100px);
    z-index:10001110;
    max-width:200px;
    transition: all 0.2s ease-in-out;
    cursor: url("cursor_pointer.png") 0 0, pointer;
}
.mintgoback:hover{
    opacity:0.5;
}

.mintgoback.hide{
    opacity:0;
    z-index:0;
}

.mintgoback img{
    width: 100%;
}

.mintgoback:active{
    transform: scale(0.92);
}

/* CLOUDS */
.wrapper.zoomed .l1{
    transform: scale(1.01);
}

/* DIGITAL  */
.wrapper.zoomed .l2{
    transform: scale(1.05);

}

/* BLUE MOUNTAINS  */
.wrapper.zoomed .l3{
    transform: scale(1.1);
}

/* FOREST */
.wrapper.zoomed .l4{
    transform: scale(1.2);
}

/* FOG */
.wrapper.zoomed .l42{
    transform: scale(1.2);
}

/* Fog */
.wrapper.zoomed .lfog{
    transform: scale(1.1);
}

/* Mountains */
.wrapper.zoomed .l5{
    transform: scale(1.3);
}

/* Shadow */
.wrapper.zoomed .l6{
    z-index:20;
}

.wrapper.zoomed .lf1{

}

.f.zoomed{
   margin-top: 200px;
}


.mintwrapper{
    height: auto;
    margin:auto;
    line-height:40px;
    position:fixed;
    top:250px;
    width:60%;
    left:20%;
    z-index:10000;
    text-align:center;
    font-size:40px;
    color: #ff00ff !important;
    border-radius: 50%;
    opacity:1;
    transition: all 0.8s ease-in-out;

}

.mintwrapper h2{
    color: #ff00ff !important;
    margin-top:50px;
}

.mintwrapper:before{
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #fff;
    opacity: 0.9;
    z-index: -1;

    /* Applying blur */
    filter: blur(70px);
}

.mintwrapper.hide{
    opacity:0;
    z-index:0;
}

.mintbuttonbig{
    width:100%;
}

.mintbuttonbigfren{
    position: absolute;
    top:15%;
    max-width:70%;
    left:15%;
    opacity:0.6;
    border-radius:3px;
}

.wrapbigbuttonmint{
    position:relative;
    max-width:280px;
    left:calc(50% - 140px);
    translate: translateX(-50%);
    cursor: url("cursor_pointer.png") 0 0, pointer;
}

.wrapbigbuttonmint:active{
    transform: scale(0.98);
}

.smallfont{
    font-size:15px;
    line-height:18px;
}

.number-input{
    font-size:22px;
    margin-bottom:15px;
}

.number-input input{
    cursor: url("cursor_pointer.png") 0 0, pointer;
    font-size:22px;
    text-align: center;
    background: rgba(255,255,255,0.3);
    border: 2px solid #000;
    border-radius:0;
}

.number-input button{
    background-color: #ff00ff;
    cursor: url("cursor_pointer.png") 0 0, pointer;
    font-size:22px;
    width:36px;
    margin-left:3px;
    margin-right: 3px;
}

.number-input button:active{
    transform: scale(0.95);
}

@media all and (max-width:1080px){
    .wrapbigbuttonmint
    {
        max-width:200px;
        left:calc(50% - 100px);
    }
}

@media all and (max-width:800px)
{
    .mintwrapper h2{
        margin-top:10px;
    }

    .mintwrapper{
        top: 280px;
        width:70%;
        left:15%;
    }

    .mintwrapper h5{
        margin-top:5px;
        margin-bottom: 10px;
    }

    .wrapbigbuttonmint
    {
        max-width:160px;
        left:calc(50% - 80px);
    }
}


.legacybanner{
    z-index:100000;
    position: relative;
    top: 0;
    left:0;
    right:0;
    padding:30px;
    text-align:center;
    color: #000;
    background: #2eff2e;
}